import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import "../assets/styles/layout.css";
import useFilterStateStore from "../stores/useFilterStateStore";

const GenericLayout = ({ title, h1, children, noHeader, breadCrumbs }) => {
  // const offsetTop = useOffsetTop();

  const { setIsFilterPanelOpen } = useFilterStateStore((state) => state);
  return (
    <div
      id={"regular-layout"}
      className={"bg-skin-500"}
      onClick={() => setIsFilterPanelOpen(false)}
    >
      <Helmet>
        <meta charSet="utf-8" />
        {title ? <title>{title}</title> : <title>ADM-Mobilier</title>}
      </Helmet>
      <NavBar />
      <main>
        {(title || h1) && !noHeader && (
          <Header h1={h1} title={title} breadCrumbs={breadCrumbs} />
        )}
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default GenericLayout;
